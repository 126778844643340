<template>
  <ViewportBackgroundContainer>
    <div class="py-4 md:py-5">
      <h3
        class="text-center pb-4 font-serif"
        data-i18n="blogHomeListTitle"
      >
        {{ $t('blogHomeListTitle') }}
      </h3>
      <ScreenWidthProvider v-slot="{ isNarrower, isWider }">
        <div
          v-if="isWider('md', 1)"
          class="hidden md:block"
        >
          <Swiper v-bind="sliderOptions">
            <HomeBlogCard
              v-for="post in list"
              :key="`t-${post.id}`"
              class="my-6"
              :post="post"
            />
          </Swiper>
        </div>
        <div
          v-if="isNarrower('md', 1)"
          class="block md:hidden"
        >
          <Swiper v-bind="sliderOptions">
            <HomeBlogCard
              v-for="post in list"
              :key="`t-${post.id}`"
              :post="post"
            />
          </Swiper>
        </div>
        <div class="flex justify-center mt-12 w-full">
          <button
            class="btn btn-blue rounded-3xl px-12 py-2"
            data-i18n="seeAll"
            @click="navigateTo(blogUrl)"
          >
            {{ $t('seeAll') }}
          </button>
        </div>
      </ScreenWidthProvider>
    </div>
  </ViewportBackgroundContainer>
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()
    const blogUrl = localeURLs.blog

    return {
      blogUrl,
    }
  },

  data () {
    return {
      sliderOptions: {
        slidesPerView: 1,
        pagination: true,
        dotsAlign: 'outside',
        dotsType: 'dark',
        spaceBetween: 30,
        constantDisplayNavigation: true,
        navigation: true,
        arrowsType: 'circular',
        loadingSkeletonAnimation: 'skeleton-slides',
        loadingSkeletonAspect: false,
        loadingSkeletonHeight: '400px',
        loop: true,
        onlyRenderVisibleSlides: true,
        preventSwiperUpdate: true,
        autoplay: {
          delay: 5000,
        },

        breakpoints: {
          [getTailwindBreakpointsPx().md]: {
            slidesPerView: 3,
          },
        },
      },
    }
  },

  computed: {
    ...mapState(useBlogStore, {
      rawList: 'list',
    }),

    list () {
      let showOnHomeItems = this.rawList
        .filter(item => item.featured_on_startpage === 1)
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))

      if (showOnHomeItems.length < 9) {
        const additionalItems = this.rawList
          .filter(item => !showOnHomeItems.includes(item))
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))

        showOnHomeItems = showOnHomeItems.concat(additionalItems).slice(0, 9)
      }

      return showOnHomeItems
    },
  },

})
</script>

<style lang="scss" scoped>
.btn {
  text-transform: none;
  font-size: 1.5rem;
}
</style>
