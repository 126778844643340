<template>
  <div
    class="blog-card flex flex-col relative bg-white text-black drop-shadow-below-blur hover-scale-img cursor-pointer"
    @click="navigateTo(`${blogUrl}/${post.url}`)"
  >
    <div class="max-h-px-400">
      <div class="aspect-4/4 relative">
        <ResponsiveImage
          class="size-full top-0 max-h-px-400"
          position="absolute"
          :image="post.hero_image_cloudinary"
          :alt="post.title"
          cover
        />
      </div>
    </div>
    <div class="px-4 flex blog-card__header">
      <div class="flex flex-col align-start h-full pt-2">
        <div class="h5 mb-0">
          {{ post.title }}
        </div>
      </div>
      <fa
        class="text-blue"
        icon="fa-chevron-right"
        size="2xl"
      />
    </div>
  </div>
</template>

<script setup>
const { urls: localeURLs } = useLocale()
const blogUrl = localeURLs.blog

defineProps({
  post: {
    type: Object,
    default: () => {},
  },
})
</script>

<style lang="scss" scoped>
.blog-card {
  &__header {
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .h5 {
      font-size: 0.95rem;
      @media (min-width: theme('screens.xl')) {
        font-size: 1rem;
      }
    }
  }
}
</style>
